import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EntityType } from '@remento/types/entity';
import { UserOnboardingActionType } from '@remento/types/user';
import { notNull } from '@remento/utils/array/notNull';

import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { OnboardingIntro, OnboardingStep } from '@/modules/onboarding/components/OnboardingIntro/OnboardingIntro';
import { OnboardingNoActiveAccountDialog } from '@/modules/onboarding/components/OnboardingNoActiveAccountDialog/OnboardingNoActiveAccountDialog';
import { getCheckoutAudiencePath, RementoPage } from '@/modules/routing';
import { RoutePath } from '@/modules/routing/types/routing.types';
import { useServices } from '@/Services';
import { useSignOut, useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';
import { useProjectsQuery } from '@/services/api/project';
import { useOnboardingSteps } from '@/services/cms/onboarding/onboarding.service.hook';
import { OnboardingStepType } from '@/services/cms/onboarding/onboarding.types';

function OnboardingIntroContainer() {
  const {
    onboardingAnalyticsService,
    userService,
    redirectService,
    aclCacheService,
    projectCacheService,
    entityCacheManagerService,
  } = useServices();

  const navigate = useNavigate();
  const [purchaseDialogOpen, setPurchaseDialogOpen] = useState(false);
  const [onboardingType, setOnboardingType] = useState<OnboardingStepType | null>(null);

  const signOut = useSignOut();
  const user = useUser();
  const personQuery = usePersonQuery(user?.personId);
  const projectsQuery = useProjectsQuery();

  const email = useMemo(() => {
    if (!user) {
      return;
    }
    return user.communicationChannels.email;
  }, [user]);

  // Steps
  useEffect(() => {
    aclCacheService.getCurrentUserAclGroupMembers().then(async (groupMembersIds) => {
      const groupMembers = await Promise.all(
        groupMembersIds.map((groupMemberId) => aclCacheService.getAclGroupMember(groupMemberId)),
      );

      const projectId = groupMembers.filter(notNull).find((groupMember) => groupMember.groupType === EntityType.PROJECT)
        ?.groupMetadata.projectId;

      if (projectId) {
        const project = await projectCacheService.getProject(projectId);
        switch (project?.configuration.type) {
          case 'BABYBOOK':
            setOnboardingType('purchaser.babybook');
            return;
          case 'AUTOBIOGRAPHY':
            setOnboardingType('purchaser.autobiography');
            return;
          case 'BIOGRAPHY':
            setOnboardingType('purchaser.biography');
            return;
        }
      }

      // The user will actually not go through the onboarding.
      // We only need to show the first step.
      setOnboardingType('purchaser.biography');
    });
  }, [aclCacheService, projectCacheService]);

  const onboardingStepsQuery = useOnboardingSteps(onboardingType);
  const steps = useMemo<OnboardingStep[] | null>(() => {
    if (!onboardingStepsQuery.data) {
      return null;
    }

    return onboardingStepsQuery.data.map((step) => ({
      id: step.id,
      title: step.title,
      description: step.description,
      desktopAssetUrl: step.desktopAssetUrl,
      desktopAssetType: step.desktopAssetMimeType.includes('image') ? 'image' : 'video',
      mobileAssetUrl: step.mobileAssetUrl,
      mobileAssetType: step.mobileAssetMimeType.includes('image') ? 'image' : 'video',
    }));
  }, [onboardingStepsQuery.data]);

  const [activeStep, setActiveStep] = useState<OnboardingStep | null>(null);
  const activeStepIndex = useMemo(
    () => (activeStep && steps ? steps.findIndex((s) => s.id === activeStep.id) : -1),
    [activeStep, steps],
  );

  // Set the initial step
  useEffect(() => {
    if (steps !== null) {
      setActiveStep(steps[0]);
    }
  }, [steps]);

  useEffect(() => {
    if (user == null || projectsQuery.data == null) return;

    if (user.availableSubscriptions.length === 0 && projectsQuery.data.length == 0) {
      setPurchaseDialogOpen(true);
      return;
    }
  }, [user, projectsQuery.data]);

  // Callbacks
  const handleNextStep = useCallback(async () => {
    if (!steps || activeStepIndex === -1) {
      return;
    }

    // Trigger the onboarding started when the user presses get started
    if (activeStepIndex === 0) {
      onboardingAnalyticsService.onOnboardingStarted();
    }

    // If it's on the last step, adds the first prompt
    if (activeStepIndex === steps.length - 1) {
      const user = await userService.getUser();
      if (user === null) {
        console.error('No user signed in');
        return;
      }

      await entityCacheManagerService.mutate(
        userService.createSetUserOnboardingHistoryMutation(user, UserOnboardingActionType.PURCHASER),
      );
      await userService.refreshUser();

      const redirect = await redirectService.consumeRedirect('user-onboarded');
      navigate(redirect ?? '/');
      return;
    }

    setActiveStep(steps[activeStepIndex + 1]);
  }, [
    activeStepIndex,
    entityCacheManagerService,
    navigate,
    onboardingAnalyticsService,
    redirectService,
    steps,
    userService,
  ]);

  const handlePreviousStep = useCallback(() => {
    if (!steps || activeStepIndex === -1) {
      return;
    }

    if (activeStepIndex > 0) {
      setActiveStep(steps[activeStepIndex - 1]);
    }
  }, [activeStepIndex, steps]);

  const handlePurchase = useCallback(async () => {
    navigate(RoutePath.Checkout);
  }, [navigate]);

  const handleUseCredits = useCallback(async () => {
    navigate(getCheckoutAudiencePath('biography'));
  }, [navigate]);

  // Analytics
  useEffect(() => {
    onboardingAnalyticsService.onOnboardingArrived();
  }, [onboardingAnalyticsService]);

  if (!steps || !activeStep) {
    return null;
  }

  return (
    <>
      <OnboardingIntro
        activeStep={activeStep}
        steps={steps}
        finishButtonLabel="Add prompts"
        title={activeStepIndex === 0 ? 'WELCOME TO REMENTO' : 'WHAT TO EXPECT'}
        onNextStep={handleNextStep}
        onPreviousStep={handlePreviousStep}
        onChangeStep={setActiveStep}
      />

      {user != null && user.coupons != null && user.coupons.length > 0 ? (
        <RMConfirmationModal
          open={purchaseDialogOpen}
          title="You have credits ready 🎁 "
          message="Add a new storyteller now and schedule their welcome message for any future date. More family, more stories!"
          btnFullWidth
          confirmLabel="Add a Storyteller"
          cancelLabel={null}
          onConfirm={handleUseCredits}
        />
      ) : (
        <OnboardingNoActiveAccountDialog
          open={purchaseDialogOpen}
          name={personQuery?.data?.name?.full ?? ''}
          email={email ?? ''}
          onPurchase={handlePurchase}
          onLogout={signOut}
        />
      )}
    </>
  );
}

export function OnboardingIntroPage() {
  return (
    <RementoPage type="default">
      <OnboardingIntroContainer />
    </RementoPage>
  );
}
