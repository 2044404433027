import { useCallback, useState } from 'react';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMDialog } from '@/components/RMDialog';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { RMText } from '@/components/RMText/RMText';

import { FooterWrapper, LogoutLink, LogoutWrapper } from './OnboardingNoActiveAccountDialog.styles';

interface OnboardingNoActiveAccountDialogProps {
  open: boolean;
  name: string;
  email: string;
  onPurchase: () => void;
  onLogout: () => Promise<void>;
}

export function OnboardingNoActiveAccountDialog({
  open,
  name,
  email,
  onPurchase,
  onLogout,
}: OnboardingNoActiveAccountDialogProps) {
  return (
    <RMDialog.Root open={open}>
      <RMDialog.Content>
        <RMDialog.Header title="No active account found" />

        <RMDialog.Body>
          <RMText type="sans" size="s" color="on-surface-primary">
            We couldn't find an active account associated with{' '}
            <RMText type="sans" size="s" bold color="on-surface-primary">
              {email}
            </RMText>
            . To continue, purchase a subscription or request an invitation from a current customer.
          </RMText>
        </RMDialog.Body>

        <RMDialog.Footer>
          <FooterWrapper>
            <RMButton background="primary" fullWidth onClick={onPurchase}>
              Purchase Remento
            </RMButton>

            <LogoutWrapper>
              <RMText type="sans" size="xs" color="on-surface-secondary">
                Not{' '}
                <RMText type="sans" bold size="xs" color="on-surface-secondary">
                  {name}
                </RMText>
                ? <LogoutLink onClick={onLogout}>Log out</LogoutLink>.
              </RMText>
            </LogoutWrapper>
          </FooterWrapper>
        </RMDialog.Footer>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
