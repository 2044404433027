import { styled } from '@linaria/react';

export const FooterWrapper = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
`;

export const LogoutLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export const LogoutWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: var(--spacing-md);
`;
